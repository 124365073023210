import { Grid, Typography } from "@mui/material";
import React from "react";
import Img from "../../../src/images/contact-us.jpg"
import "./styles.css";
import { Footer } from "../Footer/Footer";
import { Header } from "../Header/Header";

export function ContactUs() {
  return (
    <>
    <Header />
      <div className="container-fluid main-contact-us-wrapper">
        <div className="row m-x-0">
            <div className="col"><img src={Img} alt="img" className="img-fluid"/></div>
        </div>
        <div className="container">
            <div className="row my-4">
            <div className="col">
                <h3 className="text-center">OFFICE ADDRESS</h3>
                <p className="text-center">Wadhwan Complex Laxmi Nagar</p>
                </div>
          <div className="col">
            <h3 className="text-center">PHONE NUMBER</h3>
            <p className="text-center">7836973698</p>

          </div>
          <div className="col">
            <h3 className="text-center">GENERAL ENQUIRY</h3>
            <p className="text-center">Info@infinosys.com</p>
          </div>
            </div>
          
        </div>
        <h5 className="text-center"> Our Customer Service team is waiting to assist you.</h5>
        <p className="text-center">Please allow one bussiness working day response time in order for
        us to full fill address your queries.</p>
      </div>
      <Footer />
    </>
  );
}
