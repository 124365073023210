import React from "react";
import "./styles.css";

export function HomeService() {
  return (
    <>
   
   <h2 className="text-center my-3">Our Service</h2>
   <div className="workflow-managemet-wrapper">
        <div className="work-flow-content-box">
          <div className="row m-0 my-5">
            <div className="col-12 col-sm-4">
              <div className="column-workflow-info p-3">
                <div className="workflow-heading d-flex gap-3 align-items-center">
                  <div className="work-flow-img-box">
                    {/* <img className="tech-img" alt="icons"/> */}
                    <i class="bi bi-gear-wide-connected"></i>
                  </div>
                  <h6 className="m-0 text-light">Software Development</h6>
                </div>
                <p className="m-0 my-3">Elevate your software development with our expert team. We specialize in cutting-edge technologies and deliver innovative solutions tailored to your unique needs. From concept to deployment, we're your trusted partner for software success.</p>
                  <ul className="workflow-list-items">
                    <li>Web Development</li>
                    <li>Mobile App development</li>
                    <li>UX Designing</li>
                    <li>Software Testing</li>
                    <li>SOftware deployment</li>
                  </ul>
              </div>
            </div>
            <div className="col-12 col-sm-4">
              <div className="column-workflow-info p-3">
                <div className="workflow-heading d-flex gap-3 align-items-center">
                  <div className="work-flow-img-box">
                    {/* <img className="tech-img" alt="icons"/> */}
                    <i class="bi bi-gear-wide-connected"></i>
                  </div>
                  <h6 className="m-0 text-light">Software Trainings</h6>
                </div>
                <p className="m-0 my-3">Upskill your team with our comprehensive software training programs. Our expert instructors provide hands-on experience and industry-relevant knowledge. From foundational courses to advanced certifications, we empower your employees to excel in today's competitive tech landscape</p>
                  <ul className="workflow-list-items">
                    <li>Staffing Solutions</li>
                    <li>IT Staffing</li>
                    <li>Search and Recruitment</li>
                    <li>Skilling and Learning Solutions</li>
                  </ul>
              </div>
            </div>
            <div className="col-12 col-sm-4">
              <div className="column-workflow-info p-3">
                <div className="workflow-heading d-flex gap-3 align-items-center">
                  <div className="work-flow-img-box">
                    {/* <img className="tech-img" alt="icons"/> */}
                    <i class="bi bi-gear-wide-connected"></i>
                  </div>
                  <h6 className="m-0 text-light">Workforce Management</h6>
                </div>
                <p className="m-0 my-3">Optimize your workforce with our innovative management tools. From time tracking and attendance to project planning and resource allocation, our solutions streamline operations and boost productivity. Empower your team to work efficiently and achieve maximum results.</p>
                  <ul className="workflow-list-items">
                    <li>Staffing Solutions</li>
                    <li>IT Staffing</li>
                    <li>Search and Recruitment</li>
                    <li>Skilling and Learning Solutions</li>
                  </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
