import React from "react";

export function Services() {
  return (
    <>
      <div className="container-fluid main-contact-us-wrapper">
        <div className="row m-x-0">
          <p className="text-center">Image with Text</p>
        </div>
        <div class="card" className="boxStyle">
          {/* <img class="card-img-top" src="..." alt="Card image cap" /> */}
          <p> Service page will come</p>
          <div class="card-body">
            <p class="card-text">
              Some quick example text to build on the card title and make up the
              bulk of the card's content.
            </p>
          </div>
        </div>
          
      </div>
    </>
  );
}
