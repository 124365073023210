import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import CardActionArea from "@mui/material/CardActionArea";
import { Header } from "../Header/Header";
import { Footer } from "../Footer/Footer";

export default function Customers() {
  return (
    <>
      <Header />
      <div className="container-fluid footer-section-main">
        <div className="container">
          <div className="row my-4">
            <div className="col-sm-12 col-md-4 col-lg-4">
              {/* <ul className="footer-link">
              <li><a>Home</a></li>
            </ul> */}
              <Card sx={{ maxWidth: 345 }}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="140"
                    image="/static/images/cards/contemplative-reptile.jpg"
                    alt="web-dev-icon"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      Credit Bajaar
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ color: "text.secondary" }}
                    >
                      Credit Bajaar is a Financial Technology in Delhi/NCR. We
                      target to make financial products affordable and easily
                      accessible to the common man. Our team consists of
                      technocrats and career bankers with more than 15 years of
                      experience from top-notch institutions and various global
                      banks
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4">
              <Card sx={{ maxWidth: 345 }}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="140"
                    image="/static/images/cards/contemplative-reptile.jpg"
                    alt="mobile-dev-icon"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      Udyami Sathi
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ color: "text.secondary" }}
                    >
                      Udyami Sathi, your trusted partner in the Banking &
                      Finance sector for over 15 years. Founded by seasoned
                      professionals with extensive experience in banking and
                      finance, Udyami Sathi is committed to simplifying the loan
                      acquisition process and making financial solutions easily
                      accessible to individuals and businesses alike
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4">
              <Card sx={{ maxWidth: 345 }}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="140"
                    image="/static/images/cards/contemplative-reptile.jpg"
                    alt="it-conaulting-icon"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      SGAS Services
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ color: "text.secondary" }}
                    >
                      Credit Bajaar is a Financial Technology in Delhi/NCR. We
                      target to make financial products affordable and easily
                      accessible to the common man. Our team consists of
                      technocrats and career bankers with more than 15 years of
                      experience from top-notch institutions and various global
                      banks
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
