import React from "react";
import "./styles.css";
import { Header } from "../Header/Header";
import { Footer } from "../Footer/Footer";
import Service1 from "../../Assets/images/itTrainings.jpg";
import Service2 from "../../Assets/images/itConsultings.jpg";
import Service3 from "../../Assets/images/mobileDevelopment.jpg";
import Service4 from "../../Assets/images/webDevelopment.jpg";
import bullet from "../../images/bullet-list.svg";
import getkins from "../../images/jetkings.png";
import docker from "../../images/docker.png";

export function About() {
  return (
    <>
    <Header />
    <div className="container">
      <div className="row about-us-content-wrapper m-0">
        <div className="col-sm-12 aboutus-page-content py-5">
          <div className="row about-page-information m-0">
            <div className="col-12 col-sm-6 col-lg-6 px-3">
              <div className="about-image-container">
                <img className="img-fluid" alt="image" src={Service1}/>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-6 px-3">
              <div className="about-page-info-content">
                <h3 className="about-heading">About Infinosys</h3>
                <p>Infinosys is a leading provider of Information Technology consulting and services company in Delhi, specializing in cutting-edge technologies that drive digital transformation. With a team of highly skilled professionals and a proven track record, we offer a comprehensive suite of services tailored to meet your unique needs.
                Our expertise spans across a wide range of areas, including cloud computing, big data analytics, digital transformation strategies, Web Development,Mobile App Development, Testing,UX Designing and more. We work closely with our clients to understand their business objectives and develop innovative solutions that deliver measurable results.
                </p>
              </div>
            </div>
          </div>

        </div>
        <div className="col-sm-12 aboutus-page-content py-5">
          <div className="row about-page-information even-number-row m-0">
            <div className="col-12 col-sm-6 col-lg-6 px-3">
              <div className="about-image-container">
                <img className="img-fluid" alt="image" src={Service1}/>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-6 px-3">
              <div className="about-page-info-content">
                <h3 className="about-heading">Web Development</h3>
                <p>Web development support offers technical assistance for website and web application development. It includes troubleshooting, bug fixing, maintenance, updates, and optimization.
                  Support services ensure smooth functioning, security, and performance of web solutions.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-12 aboutus-page-content py-5">
          <div className="row about-page-information m-0">
            <div className="col-12 col-sm-6 col-lg-6 px-3">
              <div className="about-image-container">
                <img className="img-fluid" alt="image" src={Service1}/>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-6 px-3">
              <div className="about-page-info-content">
                <h3 className="about-heading">Staffing Solution</h3>
                <p>Infinosys provide skilled IT professionals to organizations on a temporary or permanent basis. These services help companies meet urgent project needs, fill skill gaps, and streamline hiring processes.
                Staffing firms specialize in various IT roles, including software development, cybersecurity, data science, and network engineering
                </p>
              </div>
            </div>
          </div>

        </div>
        <div className="col-sm-12 aboutus-page-content py-5">
          <div className="row about-page-information even-number-row m-0">
            <div className="col-12 col-sm-6 col-lg-6 px-3">
              <div className="about-image-container">
                <img className="img-fluid" alt="image" src={Service1}/>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-6 px-3">
              <div className="about-page-info-content">
                <h3 className="about-heading">Software Training</h3>
                <p>Infyosys provides comprehensive learning programs to enhance IT skills. Courses cover a wide range of technologies like Java , Java Script, React jS, HTML and other technologies as well, including programming languages.
                Training is delivered through instructor-led sessions, online courses, and hands-on projects. we provides training in colleges as well.</p>
              </div>
            </div>
          </div>
        </div>

      
      </div>


    </div>

      <Footer />
    </>
  );
}
