import React from "react";

export default function OurValues() {
  return (
    <>
      <h2 className="text-center my-3">Our Central Values</h2>
      <div className="container-fluid main-contact-us-wrapper m-0 p- 0">
        <div className="our-servies-wrapper">
          <div className="row our-services-page m-0">
            <div className="col-12 col-sm-3 col-lg-3">
              <div className="oue-service-content">
                <h1 className="our-servies-head">
                  Our
                  <br />
                  Ethics
                </h1>
                <p className="our-servies-paragraph">
                  We walk on the path of openness& honesty in all forms of
                  communication, both internally and externally. We despise
                  discrimination against languages, complexion, gender, or
                  religion.
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-3 col-lg-3">
              <div className="oue-service-content">
                <h1 className="our-servies-head">
                  Knowledge
                  <br />
                  Sharing
                </h1>
                <p className="our-servies-paragraph">
                  Our disciplined, repetitive, and self motivated efforts result
                  in giving the community knowledge in the form of one-to-one
                  interaction and blogs.
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-3 col-lg-3">
              <div className="oue-service-content">
                <h1 className="our-servies-head">
                  Quality <br />
                  First
                </h1>
                <p className="our-servies-paragraph">
                  We are a firm believer of consistency in quality without any
                  compromise. We work in the direction of continuous innovation
                  and five-star effort.
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-3 col-lg-3">
              <div className="oue-service-content">
                <h1 className="our-servies-head">
                  Customer <br />
                  Authenticity
                </h1>
                <p className="our-servies-paragraph">
                  One of our mottos is to serve our customer's genuine interest
                  and authentic inputs with a mission to deliver tactile impact.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
