import React from "react";
import WIPImage from '../../../src/Assets/images/WIPImage.jpeg'


export default function Trainings() {
  return (
    <>
      <h2 className="text-center my-3">Software Training</h2>

      <div className="text-center my-3">
          {/* <img class="card-img-top" src="..." alt="Card image cap" /> */}
          <img className="text-center my-3" alt="logo" src={WIPImage}/>
         

      </div>
    </>
  );
}
