import React from 'react';
import { Header } from '../Header/Header';
import { Footer } from '../Footer/Footer';
import Banner from './Banner';
import Clients from '../Clients/Clients';
import Trainings from '../Trainings/Trainings';
import WeDo from '../WeDo/WeDo';
import { HomeService } from '../HomeService/HomeService';
import TechnologyStack from '../TechnologyStack/TechnologyStack';
import OurValues from '../OurValues/OurValues';
import HomeContact from '../HomeContact/HomeContact';

export function Home(){
    return (
        <>
        <Header />
        <Banner />
        <HomeService />
        {/* <WeDo /> */}
        <TechnologyStack />
        <Trainings />
        <OurValues />
        <Clients />
        <HomeContact />
        <Footer />
        </>
    )
}