import { Grid, Typography } from "@mui/material";
import React from "react";
import InfinosysNew from '../../Assets/images/InfinosysNew.jpg';
import newLogo from '../../Assets/images/newLogo.jpeg'

export function Header() {
  return (
    <>
    <nav class="navbar navbar-expand-lg navbar-light bg-primary-custom py-3">
  <div class="container-fluid">
    <a class="navbar-brand" href="#"><img className="img-fluid" src={newLogo} alt="logo"/></a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarTogglerDemo02">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
      <li class="nav-item px-4">
          <a class="nav-link active text-white" aria-current="page" href="/home">Home</a>
        </li>
        <li class="nav-item px-4">
          <a class="nav-link active text-white" aria-current="page" href="/about">About</a>
        </li>
        <li class="nav-item px-4">
          <a class="nav-link text-white" href="#">Services</a>
        </li>
        <li class="nav-item px-4">
          <a class="nav-link text-white" href="#">Partner</a>
        </li>
        <li class="nav-item px-4">
          <a class="nav-link text-white" href="/contact">Contact us</a>
        </li>
        <li class="nav-item px-4">
          <a class="nav-link text-white" href="#">Career</a>
        </li>
      </ul>
      {/* <form class="d-flex">
        <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search"/>
        <button class="btn btn-outline-white text-white header-btn-top" type="submit">Search</button>
      </form> */}
    </div>
  </div>
</nav>
      {/* <Grid container spacing={2} className="mainClass">
        <Grid item xs={2} className="logoClass">
        <img src={InfinosysNew} alt="Banner1"/>
        </Grid>
        <Grid item xs={2}>
          <Typography>About us</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography>Our Services</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography>Clients</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography>Contact US</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography>Career</Typography>
        </Grid>
      </Grid> */}
    </>
  );
}
