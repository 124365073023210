import React from 'react';
import { createBrowserRouter } from "react-router-dom";
import { Home } from '../Pages/Home';
import { ContactUs } from '../Pages/ContactUs/ContactUs';
import { About } from '../Pages/About/About';
import Customers from '../../src/Pages/Customers/Customers';
import { Services } from '../Pages/Services/Services';

const routers = createBrowserRouter([
    {
        path: "/",
        element: <Home />
    },
    {
        path: "/home",
        element: <Home />
    },
    {
        path: "contact",
        element: <ContactUs />
    },
    {
        path: "about",
        element: <About />
    },
    {
        path: "customers",
        element: <Customers />
    },
    {
        path: "services",
        element: <Services />
    }
]);

export default routers;