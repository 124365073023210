import {Container,Grid } from "@mui/material";
import Banner1 from '../../../src/Assets/images/Banner1.jpeg';
import Banner2 from '../../../src/Assets/images/Banner2.jpg';
import Banner5 from '../../../src/Assets/images/Banner5.jpeg';
import NewBanner1 from '../../../src/Assets/images/NewBanner1.jpeg';
import NewBanner2 from '../../../src/Assets/images/NewBanner2.jpeg'
import NewBanner3 from '../../../src/Assets/images/NewBanner3.jpeg'
import NewBanner4 from '../../../src/Assets/images/NewBanner4.jpeg';
import NewBanner5 from '../../../src/Assets/images/NewBanner5.jpeg'
import NewBanner6 from '../../../src/Assets/images/NewBanner6.jpeg';
import NewBanner7 from '../../../src/Assets/images/NewBanner7.jpeg'





export default function Banner(){
    return(
    <>
   <div id="carouselExample" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-inner">
                {/* <div className="carousel-item active">
                    <img src={Banner2} className="d-block w-100 img-fluid" alt="First slide" />
                </div> */}
                 <div className="carousel-item active">
                    <img src={NewBanner1} className="d-block w-100 img-fluid" alt="First slide" />
                </div>
                {/* <div className="carousel-item">
                    <img src={Banner1} className="d-block w-100 img-fluid" alt="Second slide" />
                </div> */}
                  <div className="carousel-item">
                    <img src={NewBanner2} className="d-block w-100 img-fluid" alt="Second slide" />
                </div>
                {/* <div className="carousel-item">
                    <img src={Banner2} className="d-block w-100 img-fluid" alt="Third slide" />
                </div> */}
                 <div className="carousel-item">
                    <img src={NewBanner3} className="d-block w-100 img-fluid" alt="Third slide" />
                </div>
                {/* <div className="carousel-item">
                    <img src={Banner5} className="d-block w-100 img-fluid" alt="Third slide" />
                </div> */}
                  <div className="carousel-item">
                    <img src={NewBanner4} className="d-block w-100 img-fluid" alt="Fourth slide" />
                </div>
                <div className="carousel-item">
                    <img src={NewBanner5} className="d-block w-100 img-fluid" alt="Fifth slide" />
                </div>
                <div className="carousel-item">
                    <img src={NewBanner6} className="d-block w-100 img-fluid" alt="Sixth slide" />
                </div>
                <div className="carousel-item">
                    <img src={NewBanner7} className="d-block w-100 img-fluid" alt="Seventh slide" />
                </div>
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button>
        </div>
    </>
    )
}
