import React from "react";
import "./styles.css";

export default function HomeContact() {
  return (
    
      <div className="container-fluid main-contact-us-wrapper m-0 p-0">
        <div className="home-page-contact-us m-0">
        
          <h1 className="text-center cana_it">Cana�?t wait to <span className="change-color">meet you!</span></h1>
        
            <p class="card-text text-center content-page-paragraph">
            Give your business a boost and get ready to give your customers an exceptional experience. Drop a line and we will be back to you as soon as possible.
            </p>
          <p className="loaction-paragraphy text-center mb-0">Delhi | Noida</p>
          <p className="loaction-info text-center mb-0"><span>info@infinosys.com </span>| 7836973698</p>
            
            </div>
      </div>
    
  );
}
