import * as React from "react";

//import getkins from "../../images/jetkings.png";
import getkins from "../../images/jetkings.png";
import docker from "../../images/docker.png";


export default function TechnologyStack() {
  return (
    <>
        <h2 className="text-center my-3">Technology Stack</h2>
        <div className="new-technology-warpper">
        <div className="new-technology-content">
          <div className="row row-technology-section m-0">
            <div className="col-12 col-sm-6 technology-column-box">
              <div className="technology-info-box">
                <h5 className="texhnology-title text-center">UX Designing</h5>
                <div className="technolgy-logo-box d-flex align-items-center">
                  <img src={getkins} className="img-fluid"/>
                  <img src={docker} className="img-fluid"/>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 technology-column-box">
              <div className="technology-info-box">
                <h5 className="texhnology-title text-center">Frontend</h5>
                <div className="technolgy-logo-box d-flex align-items-center">
                  <img src={getkins} className="img-fluid"/>
                  <img src={docker} className="img-fluid"/>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 technology-column-box">
              <div className="technology-info-box">
                <h5 className="texhnology-title text-center">Backend</h5>
                <div className="technolgy-logo-box d-flex align-items-center">
                  <img src={getkins} className="img-fluid"/>
                  <img src={docker} className="img-fluid"/>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 technology-column-box">
              <div className="technology-info-box">
                <h5 className="texhnology-title text-center">Deployment</h5>
                <div className="technolgy-logo-box d-flex align-items-center">
                  <img src={getkins} className="img-fluid"/>
                  <img src={docker} className="img-fluid"/>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 technology-column-box">
              <div className="technology-info-box">
                <h5 className="texhnology-title text-center">Project Management</h5>
                <div className="technolgy-logo-box d-flex align-items-center">
                  <img src={getkins} className="img-fluid"/>
                  <img src={docker} className="img-fluid"/>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 technology-column-box">
              <div className="technology-info-box">
                <h5 className="texhnology-title text-center">Testing</h5>
                <div className="technolgy-logo-box d-flex align-items-center">
                  <img src={getkins} className="img-fluid"/>
                  <img src={docker} className="img-fluid"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    
    {/* <div className="container-fluid footer-section-main">
        <p>Technology Stack</p>
        <div className="container">
          <div className="row m-0">
            <div className="col-12 col-sm-6 col-lg-4 p-2 card-column-box">
              <div class="card">
              <div className="card-img-box">
              <img class="card-img-top" src={Service4} alt="Card image cap" />
              </div>
                <div class="card-body">
                  <h5 class="card-title">Designing</h5>
                  <p>HTML,CSS,BootStrap, Material UI</p>
                 
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-4 p-2 card-column-box">
              <div class="card">
              <div className="card-img-box">
              <img class="card-img-top" src={Service3} alt="Card image cap" />
              </div>
                <div class="card-body">
                  <h5 class="card-title">Frontend</h5>
                 <p>Java Script, React JS,Angular JS</p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-4 p-2 card-column-box">
              <div class="card">
              <div className="card-img-box">
              <img class="card-img-top" src={Service2} alt="Card image cap" />
              </div>
                <div class="card-body">
                  <h5 class="card-title">Backend</h5>
                  <p>Java , Node JS</p>
               
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-4 p-2 card-column-box">
              <div class="card">
              <div className="card-img-box">
              <img class="card-img-top" src={Service1} alt="Card image cap" />
              </div>
                <div class="card-body">
                  <h5 class="card-title">UI/UX</h5>
                  <p>Figma</p>
                
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-4 p-2 card-column-box">
              <div class="card">
              <div className="card-img-box">
              <img class="card-img-top" src={Service2} alt="Card image cap" />
              </div>
                <div class="card-body">
                  <h5 class="card-title">Digital Marketing</h5>
                  
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-4 p-2 card-column-box">
              <div class="card">
                <div className="card-img-box">
                <img class="card-img-top" src={Service3} alt="Card image cap" />
                </div>
                <div class="card-body">
                  <h5 class="card-title">Deploy</h5>
                  <p>GitHub</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}
