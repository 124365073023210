import {Container,Grid } from "@mui/material";
import Banner1 from '../../../src/Assets/images/Banner1.jpeg';
import Banner2 from '../../../src/Assets/images/Banner2.jpg';
import udyamiSathi from '../../Assets/icons/udyamiSathi.jpeg';
import creditBajaar from '../../Assets/icons/creditBajaar.jpeg';
import SGAS from '../../Assets/icons/SGAS.jpeg';
import "./styles.css";


export default function Clients(){
    return(
    <>
    <div className="our-clients-section">
        <div className="row">
            <div className="col-12">
                <h2 className="text-center my-3">Our Client's</h2>
                <div className="clients-logo-section d-flex justify-content-center gap-4 my-5">
                    <div className="company-logo-container">
                    <img className="clients-logo img-fluid" alt="logo" src={udyamiSathi}/>
                    </div>
                    <div className="company-logo-container">
                    <img className="clients-logo img-fluid" alt="logo" src={creditBajaar}/>
                    </div>
                    <div className="company-logo-container">
                    <img className="clients-logo img-fluid" alt="logo" src={SGAS}/>
                    </div> 
                    <div className="company-logo-container">
                    <img className="clients-logo img-fluid" alt="logo" src={udyamiSathi}/>
                    </div>
                    <div className="company-logo-container">
                    <img className="clients-logo img-fluid" alt="logo" src={creditBajaar}/>
                    </div>
                    <div className="company-logo-container">
                    <img className="clients-logo img-fluid" alt="logo" src={SGAS}/>
                    </div> 
                </div>
            </div>
        </div>
    </div>

    </>
    )
}

//  <Container>
//<Grid>
//<Grid item xs="12" sm={2} md={2}>
//<img src={Banner1} alt="Banner1"/>
//   </Grid>
//</Grid>
//</Container>