import React from "react";
import CopyRight from "./CopyRight";

export function Footer() {
  return (
    <>
    <div className="container-fluid footer-section-main">
      <div className="container">
        <div className="row my-4">
          <div className="col-sm-12 col-md-4 col-lg-4">
            <ul className="footer-link">
              <li><a>Home</a></li>
              <li><a>Career</a></li>
              <li><a>Clients</a></li>
              <li><a>Web Development</a></li>
            </ul>
          </div>
          <div className="col-sm-12 col-md-4 col-lg-4">
          <ul className="footer-link">
              <li><a>About Us</a></li>
              <li><a>Our Services</a></li>
              <li><a>Training Partners</a></li>
              <li><a>Mobile Apps Development</a></li>
            </ul>
          </div>
          <div className="col-sm-12 col-md-4 col-lg-4">
          <ul className="footer-link">
              <li><a>Contact Us</a></li>
              <li><a>Trainings</a></li>
              <li><a>SEO</a></li>
              <li><a>----</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
        <CopyRight />
    </>
    
  );
}
